import React from 'react'
import { useState, useEffect, useRef } from 'react'

// import Workstation from "./Workstation"
import WorkstationV2 from "./WorkstationV2"

import axios from "axios";


const API_ROOT = "https://api.mxshell.dev"
const VIEW_KEY = "SUTDVLG"
// const API_ROOT = "http://127.0.0.1:8000"

const locale = "en-SG"
const localeOptions = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    year: "numeric",
    month: "short",
    day: "numeric",
}

const PageMain = () => {

    ///////////////////////////////////////////////////////////////////////////
    // NOTE: https://stackoverflow.com/a/59631617
    const useInterval = (callback, delay) => {
        const savedCallback = useRef();
        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);
        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }


    ///////////////////////////////////////////////////////////////////////////

    const [dataSnapshot, setDataSnapshot] = useState([]);
    const getData = async () => {
        // console.log("getting data...")
        const config = {
            method: "GET",
            url: API_ROOT + "/view",
            headers: { "accept": "application/json" },
            params: { view_key: VIEW_KEY }
        };
        await axios(config)
            .then((response) => {
                // console.log(response)
                if (response.status === 200) {
                    setDataSnapshot(response.data)
                } else {
                    console.error("Unexpected non-200 status code")
                    console.error(response)
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const [hideRawData, setHideRawData] = useState(true)
    const toggleHandler = () => {
        setHideRawData((s) => {
            if (s) {
                return false
            } else {
                return true
            }
        })
    }

    // const [viewKey, setViewKey] = useState("SUTDVLG")

    const [optionAChecked, setOptionAChecked] = useState(false)
    const [optionBChecked, setOptionBChecked] = useState(false)
    const [optionCChecked, setOptionCChecked] = useState(false)
    const showRadioCheck = (value) => {
        // cast value to int
        const intValue = parseInt(value);

        if (intValue === 5) {
            setOptionAChecked(true)
            setOptionBChecked(false)
            setOptionCChecked(false)
        } else if (intValue === 10) {
            setOptionAChecked(false)
            setOptionBChecked(true)
            setOptionCChecked(false)
        } else if (intValue === 60) {
            setOptionAChecked(false)
            setOptionBChecked(false)
            setOptionCChecked(true)
        } else {
            console.log(intValue)
        }
    }
    const uncheckRadioAll = () => {
        setOptionAChecked(false)
        setOptionBChecked(false)
        setOptionCChecked(false)
    }

    const defaultInfinity = 360000 // 360000 seconds = 100 hours (almost infinite)
    const defaultRefresh = 60 // seconds
    const [dynamicInterval, setDynamicInterval] = useState(defaultInfinity) // seconds
    const handleDynamicOptions = (e) => {
        setDynamic(true)
        setDynamicInterval(e.target.value)
        showRadioCheck(e.target.value)
    }

    const [dynamic, setDynamic] = useState(false)
    const toggleDynamic = () => {
        setDynamic((s) => {
            if (s) {
                console.log("Auto Refresh turned off")
                setDynamicInterval(defaultInfinity)
                uncheckRadioAll()
                return false
            } else {
                console.log("Auto Refresh turned on")
                setDynamicInterval(defaultRefresh)
                showRadioCheck(defaultRefresh)
                return true
            }
        })
    }

    ///////////////////////////////////////////////////////////////////////////

    useInterval(() => {
        getData()
    }, dynamicInterval * 1000);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps       
    }, []);

    return (
        <div className="container px-4 mt-3" style={{ fontFamily: "Source Code Pro" }}>
            <h1 className="mb-0 text-muted fw-bold">GPU Servers</h1>
            {/* <p className="mb-0 text-muted">VIEW KEY: {viewKey}</p> */}
            <p className="mb-0 text-secondary">Last updated: {new Date().toLocaleString(locale, localeOptions)}</p>
            {/* <p className='mb-0 text-secondary'>dynamicInterval: {dynamicInterval} seconds</p> */}


            <div className="mt-2">
                <div className="form-check form-check-inline form-switch">
                    <input className="form-check-input bg-dark" type="checkbox" checked={dynamic} onChange={toggleDynamic} />
                    <label className="form-check-label text-secondary">Auto refresh every:</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input bg-dark" type="radio" name="dynamicOptions" value={5} checked={optionAChecked} onChange={handleDynamicOptions} />
                    <label className="form-check-label text-secondary">5s</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input bg-dark" type="radio" name="dynamicOptions" value={10} checked={optionBChecked} onChange={handleDynamicOptions} />
                    <label className="form-check-label text-secondary">10s</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input bg-dark" type="radio" name="dynamicOptions" value={60} checked={optionCChecked} onChange={handleDynamicOptions} />
                    <label className="form-check-label text-secondary">60s</label>
                </div>
            </div>

            <hr className="mt-1 text-white" />

            {
                dataSnapshot.map((data, index) => { return <WorkstationV2 data={data} key={index} /> })
            }

            {/* <div className="mt-3 btn btn-outline-secondary btn-sm" onClick={toggleHandler}>Toggle Raw Data</div> */}

            {
                !hideRawData ?
                    <p className="my-3 text-success">
                        <pre className="prettyprint">{JSON.stringify(dataSnapshot, null, 4)}</pre>
                    </p>
                    : null
            }

        </div>
    )
}

export default PageMain
